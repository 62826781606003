@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+ES:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
/* Global styles -------------------- */
* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  outline: none;
  margin: 0;
  padding: 0;

  ::-webkit-scrollbar {
    width: var(--s-2);
  }
  ::-webkit-scrollbar-track {
    background: var(--white);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray-light-2);
    border-radius: var(--br-md);
  }
}

:root {
  /* Colors ------------------------ */
  --yellow: rgb(255, 186, 0);
  --yellow-2: #ce8236;
  --orange: #ea7c29;
  --red: #ef4444;
  --green: #10b981;
  --blue: #3b82f6;
  --blue-2: rgb(9, 128, 239);
  --blue-light: #e6f4ff;
  --blue-light-2: #93dbe9;
  --white: #ffffff;
  --black: #000000;
  --black-opacity: #00000057;
  --gray: #6b7280;
  --gray-light: #e5e7eb;
  --gray-light-1: #e6e7eb;
  --gray-light-2: #d4d4d4;
  --gray-dark: #374151;
  --orange-brown: #ffd8bf;
  --left-navbar-bg: #343a40;
  /* Font sizes -------------------- */
  --fs-xs: 0.75rem;
  --fs-sm: 0.875rem;
  --fs-md: 1rem;
  --fs-lg: 1.125rem;
  --fs-xl: 1.25rem;
  --fs-2xl: 1.5rem;
  --fs-3xl: 1.875rem;
  --fs-4xl: 2.25rem;
  --fs-5xl: 3rem;
  --fs-6xl: 4rem;

  /* Font weights ------------------- */
  --fw-light: 300;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;

  /* Spacing ------------------------ */
  --s-1: 0.25rem;
  --s-1-larger: 0.375rem;
  --s-2: 0.5rem;
  --s-3: 0.75rem;
  --s-4: 1rem;
  --s-4-larger: 1.125rem;
  --s-5: 1.25rem;
  --s-6: 1.5rem;
  --s-7: 1.75rem;
  --s-8: 2rem;
  --s-9: 2.25rem;
  --s-10: 2.5rem;
  --s-11: 2.75rem;
  --s-12: 3rem;
  --s-13: 6.25rem;
  --s-14: 10.25rem;
  --s-15: 12.5rem;
  --s-15-larger: 18rem;
  --s-16: 25rem;

  /* Border radius ------------------ */
  --br-sm: 0.2rem;
  --br-md: 0.4rem;
  --br-lg: 0.8rem;
  --br-xl: 1.6rem;
  --br-2xl: 3.2rem;

  /* Shadows ------------------------ */
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);

  /* Loader ------------------------ */
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --primary: hsl(var(--hue), 90%, 55%);
  --trans-dur: 0.3s;
}

.borderBottomGray{
  border-bottom: 2px solid #dcdce5;
}

.LightGrayBackGround:hover{
  background-color: #f1f1f1;
}

.default-text{
  font-size: 16px;
}

.thinBoxShadow{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.smallBoxShadow{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.mediumBoxShadow{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.largeBoxShadow{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.aspectPostImg{
  aspect-ratio: 3/2;
}

.aspect4per3{
  aspect-ratio: 4/3;
}
.darkScrollBar{

}

.darkScrollBar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.darkScrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(37, 37, 37); 
  border-radius: 10px;
  background: #505050;
}
 
/* Handle */
.darkScrollBar::-webkit-scrollbar-thumb {
  background: #111827; 
  border-radius: 10px;
}

.darkChatScrollBar{

}

.darkChatScrollBar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.darkChatScrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(37, 37, 37); 
  border-radius: 10px;
  background: #505050;
}
 
/* Handle */
.darkChatScrollBar::-webkit-scrollbar-thumb {
  background: #252525; 
  border-radius: 10px;
}

.darkChatScrollBar::-webkit-scrollbar-thumb:hover {
  background: #181b24; 
}

.chat-bot-container {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1000;
}
.chat-bot-open {
  padding: 5px 10px;
  background-color: rgb(255 186 0);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.chat-bot-iframe {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.chat-bot-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
